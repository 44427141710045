module.exports = [{
      plugin: require('/Users/kazu/src/github.com/firebasejp/cast-site/node_modules/gatsby-plugin-google-analytics/gatsby-browser'),
      options: {"plugins":[],"trackingId":"UA-52147387-8"},
    },{
      plugin: require('/Users/kazu/src/github.com/firebasejp/cast-site/node_modules/gatsby-plugin-offline/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/kazu/src/github.com/firebasejp/cast-site/node_modules/gatsby-plugin-typography/gatsby-browser'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography"},
    },{
      plugin: require('/Users/kazu/src/github.com/firebasejp/cast-site/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
